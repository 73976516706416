.contentContainer {
    min-height: calc(100vh - 130px);
    width: 90%;
    margin:auto;
}
.lineBreaker {
    padding-top: 30px;
}
.ant-tabs-nav-wrap {
    margin-top: 30px;
}