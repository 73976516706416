.register-page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.log-in {
  &:hover {
    color: #87ceeb;
  }
  margin-left: 5px;
}
