.head {
  display: flex;
  align-items: center;
  height: 70px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 1px 2px #ddd;
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}
.rent-saleLogo {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  box-shadow: 0 1px 2px #ddd;
  display: grid;
  grid-template-columns: 50% 30% 20%;
  grid-gap: 10px;
  height: 100px;
}
.headerItem1 {
   display: flex;
}
.headerItem1 img { 
  width: 70px;
  margin: auto;
  object-fit: cover;
  margin-left: 70%;
}
.headerItem2 {
  display: flex;
}
.headerItem2 img { 
 width: 70px;
 margin: auto;
 object-fit: cover;
 margin-right: 70%;
}
.headerItemLast {
  display: flex;
}
.headerItemLast Button {
  margin: auto;
}

.selected {
  border-bottom: solid;
  border-color: grey;
}

.HconentStyle {
  display: flex;
  align-items: center;
  width: 40%;
  margin-right: 25px;
}
.searchInput {
  width: 80%;
  margin: auto;
  max-width: 400px;
  min-width: 300px;
}
