.addressInput {
    flex: 0 1 100%;
    max-width: 500px;
    display: block;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 6px;
    transition: all 0.2s;
    background: rgba(0, 0, 0, 0.04);
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    margin-bottom: 20px;
    margin-top: 10px;
}

.addressInput:focus {
    background-color: white;
}
.addressContainer {
    flex: 60%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    max-width: 600px;
}
.singleLine {
    flex: 0 1 100%;
}

.hidden {
    display: none;
}

.label:before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.label {
    flex: 100%;
}
.dateLabel {
    margin-bottom: 10px;
}

.container {
    width: 100%;

}

.firstContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.dateContainer {
    flex: 50%;
    display: flex;
    flex-direction: column;
    max-width: 290px;
    margin-bottom: 20px;
}

.uploadButton {
    border: 0;
    background: none;
    left: -50px;
}

.form {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    margin: 50px;
}
.formItem {
    min-width: 400px;
    flex: 1 1 33%;
}
.portionOfProperty {
    flex: 0 1 100%;
}
.saveButton {
    flex: 1 1 30%;
}
.lotSizeItem {
    max-width: 37%;
}
.lotSizeContainer {
    display: flex;
    justify-content: left;
}
.checkBoxGroup {
    max-width: 100%;
}
.ant-form-item-label {
    max-width: 100%;
}
.marginRight {
    margin-right: 20px;
}