.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .svgContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .icon {
    width: 30px;
    height: 30px;
  }
}
.like {
  position: relative;
  width: 30px;
  height: 30px;
  z-index: 2;
  left: 255px;
  top: 250px;
  cursor: pointer;
}
.hide {
   display: none;
}

.card-container {
  width: 300px;
}

.card-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: 20px;
  left: 10px;
  z-index: 2;
  color: white;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: 20px;
  right: 10px;
  z-index: 2;
  color: white;
}
.ant-carousel .slick-next::before {
  content: "";
}
.ant-carousel .slick-prev::before {
  content: "";
}
.houseItem {
  flex: 1 1 auto;
  margin-top: 10px;
}
.shaded { 
  opacity: 0.5;
}