.displayContainer {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
}
.domControl {
    height: calc(100vh - 230px);
    overflow:auto;
}

.displayContainer:after {
    content: '';
    width: 300px;
    flex: auto;
}
.loader {
     position: auto;
     bottom: 40px;
     margin: auto;
}