.imagesContainer {
  width: 90%;
  display: flex;
  height: 300px;
  overflow: hidden !important;
  margin: auto;
  margin-top: 40px;

  .image1 {
    height: 300px;
    overflow: hidden;
    width: 50%;
  }
}

.column2 {
  width: 25%;

  .image2 {
    height: 150px;
  }
}

.column3 {
  width: 25%;

  .image3 {
    height: 150px;
  }
}

.showAll {
  right: 130px !important;
  top: 380px !important;
  position: absolute !important;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px 1px !important;
}

.link {
  color: #1677ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  padding-right: 30px;
}

.form {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  margin: auto;
  margin-top: 40px;
}

.formItem {
  max-width: 400px;
  flex: 1 1 33%;
}

.ant-form-item-label {
  max-width: 100%;
}

.formItem2 {
  flex: 0 1 100%;
}
.formItem4 {
  flex: 1 1 80%;
}
.formItem3 {
  flex: 1 1 25%;
  margin-left: -30%;
}

.formItem2 .text-display {
  max-width: 500px;
}

.lotSizeItem {
  max-width: 37%;
  width: 100%;
}

.lotSizeContainer {
  display: flex;
  justify-content: left;
}

/* Modal (background) */
.modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
  /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content {
  max-height: 600px;
  width: auto;
}

#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

.leftArrow {
  position: absolute;
  font-size: 50px;
  top: 370px;
  left: 30px;
  color: #f1f1f1;
}

.rightArrow {
  position: absolute;
  font-size: 50px;
  top: 370px;
  right: 30px;
  color: #f1f1f1;
}

.allImageContainer {
  display: flex;
  margin: auto;
  width: 90%;
  flex-wrap: wrap;
}

.showAllImages {
  width: 90%;
  margin: auto;
}
.paddingRight {
  padding-right: 30px;
}
.form-container {
  padding-bottom: 30px;
}