.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 130px);
}

.login-container {
  width: 50%;
  margin: 10px;
}

.sign-in {
  &:hover {
    color: #87ceeb;
  }
}

.bottomContainer {
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.registerContent {
  color: gray;
  cursor: pointer;
  transition: color 0.3s;
  margin-top: 20px;
}
.loginForm {
  justify-content: center;
  min-width:500px;
}
.loginButton {
  display: absolute;
  right: 0;
}