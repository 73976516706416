.personal-page {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: flex-start;
  min-height: calc(100vh - 130px);
}

.display-houses {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
}

.display-houses:after {
  content: "";
  width: 300px;
  flex: auto;
}

.display-profile {
  width: 80%;
  margin-top: 30px
}

.personal-left {
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
}

.personal-right {
  flex: 4 4 80%;
}

.profileButton {
  width: 180px;
  margin: auto;
  margin-top: 30px;
}

.display-profile input {
  max-width: 400px;
}