.text-display {
  display: flex;
  align-items: center;
  padding: 8px 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  height: 10px;
  font-size: 14px;
}
